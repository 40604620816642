import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import GlobalStyles from '../styles/GlobalStyles';
import Head from "next/head";
import Layout from '../components/Layout';
import Script from 'next/script';
import '../styles/bootstrap.css';
import 'react-phone-number-input/style.css';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const WEB_KEY = '6LdaKAEiAAAAAD1JKqXO0ByRu5NrKY5i4ToPRVBo';
const App = ({
  Component,
  pageProps
}) => _jsxs(GoogleReCaptchaProvider, {
  reCaptchaKey: WEB_KEY,
  scriptProps: {
    async: false,
    defer: false,
    appendTo: "head",
    nonce: undefined
  },
  children: [_jsx(Head, {
    children: _jsx("title", {
      children: "360NET"
    })
  }), _jsx(Script, {
    src: "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/js/all.min.js",
    integrity: "sha512-Tn2m0TIpgVyTzzvmxLNuqbSJH3JP8jm+Cy3hvHrW7ndTDcJ1w5mBiksqDBb8GpE2ksktFvDB/ykZ0mDpsZj20w==",
    crossOrigin: "anonymous",
    referrerpolicy: "no-referrer"
  }), _jsxs(Layout, {
    children: [_jsx(GlobalStyles, {}), _jsx(Component, {
      ...pageProps
    })]
  })]
});
export default App;