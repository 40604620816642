import React from "react";
import Head from "next/head";
import Footer from "./Footer";
// import ScrollTopArrow from "./ScrollTopArrow";
import Navbar from "./Navbar";
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const Layout = ({
  children
}) => {
  return _jsxs("div", {
    children: [_jsx(Navbar, {}), children, _jsx(Footer, {})]
  });
};
export default Layout;