var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"Nso58h9LSI2UYTfxaL86W"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs'

Sentry.init({
  dsn: 'https://5223892706854bb293c0ec46d7c36dc2@log.fibra360.net/2',

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.2,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    new Sentry.Replay({
      // networkDetailAllowUrls: [/https:\/\/intranet.fibra360.net\/api/g],
      networkCaptureBodies: true,
      networkDetailAllowUrls: [
        window.location.origin,
        /https:\/\/intranet.fibra360.net\/api/g,
      ],
      maskAllText: false,
      maskAllInputs: false,
      maskFn: text => text,
    }),
  ],
})
